import { JsonPipe } from '@angular/common';
import {
    Component,
    computed,
    effect,
    input,
    model,
    ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Chart, ChartConfiguration, ChartEvent, ChartType } from 'chart.js';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
import annotationPlugin from 'chartjs-plugin-annotation';
import dayjs from 'dayjs';
import { BaseChartDirective } from 'ng2-charts';
import backgroundColorPlugin from './plugin/backgroundColorPlugin';
import customTitle from './plugin/customTitle';
import {
    VitalDialogData,
    VitalValuesComponent,
} from './vital-values/vital-values.component';
import bloodPointStyle from './plugin/bloodPointStyle';
import { getMinMaxValue } from './helper/curve.utility';

export const CHART_COLORS = {
    HF: 'rgba(248, 60, 58, 1)',
    RR: 'rgba(63, 153, 76, 1)',
    T: 'rgba(14, 122, 254, 1)',
    AF: 'rgba(255, 149, 0, 1)',
};

export type ChartItemPoint = {
    x: string;
    y: number;
    originValue: number;
    systolic?: number;
    diastolic?: number;
};
export type ChartData = ChartConfiguration<'line', ChartItemPoint[]>['data'];

export enum VitalType {
    heartRate = 'HF',
    bloodPressure = 'RR',
    temperatur = 'T',
    respiratoryRate = 'AF',
}

const minMaxValues = {
    [VitalType.heartRate]: { min: 40, max: 160, maxOffset: 170, offset: 10 },
    [VitalType.bloodPressure]: { min: 0, max: 300, maxOffset: 325, offset: 23 },
    [VitalType.temperatur]: { min: 35, max: 41, maxOffset: 41.5, offset: 0.5 },
    [VitalType.respiratoryRate]: {
        min: 5,
        max: 35,
        maxOffset: 40,
        offset: 2.3,
    },
};

export const initDataset: ChartData['datasets'] = [
    {
        label: VitalType.heartRate, // Herzfrequenz ROT
        data: [],
        borderColor: CHART_COLORS.HF,
        backgroundColor: CHART_COLORS.HF,
        pointStyle: 'rect',
        pointRadius: 5,
        pointHoverRadius: 10,
        fill: false,
        yAxisID: 'y',
    },
    {
        label: VitalType.bloodPressure, // Blutdruck GRÜN
        data: [],
        borderColor: CHART_COLORS.RR,
        backgroundColor: CHART_COLORS.RR,
        fill: false,
        yAxisID: 'y2',
        showLine: false,
        pointStyle: bloodPointStyle,
    },
    {
        label: VitalType.temperatur, // Körpertemperatur (°C) Blau
        data: [],
        borderColor: CHART_COLORS.T,
        backgroundColor: CHART_COLORS.T,
        pointStyle: 'circle',
        pointRadius: 5,
        pointHoverRadius: 10,
        fill: false,
        yAxisID: 'y3',
    },
    {
        label: VitalType.respiratoryRate, // Atemfrequenz (Atemzüge/Minute)
        data: [],
        borderColor: CHART_COLORS.AF,
        backgroundColor: CHART_COLORS.AF,
        pointStyle: 'cross',
        pointRadius: 10,
        pointRotation: 45,
        pointBorderWidth: 2,
        pointHoverRadius: 15,
        fill: false,
        yAxisID: 'y4',
    },
];
@Component({
    selector: 'app-curve',
    standalone: true,
    imports: [BaseChartDirective, JsonPipe],
    templateUrl: './curve.component.html',
    styleUrls: ['./curve.component.scss'],
})
export class CurveComponent {
    @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

    public plugins: ChartConfiguration['plugins'] = [
        annotationPlugin,
        backgroundColorPlugin,
        customTitle,
    ];

    public datesArray = input.required<string[]>();

    public datasets = model<ChartData['datasets']>(initDataset);

    public lineChartData = computed<ChartData | undefined>(() => ({
        labels: this.datesArray(),
        datasets: this.datasets().map((item) => {
            return {
                ...item,
                data: item.data.map((e) => {
                    const label: VitalType = item.label as VitalType;
                    return {
                        x: e.x,
                        y: getMinMaxValue(
                            e.y,
                            minMaxValues[label].min,
                            minMaxValues[label].max,
                            minMaxValues[label].maxOffset,
                            minMaxValues[label].offset
                        ),
                        originValue: e.originValue,
                        diastolic: e.diastolic,
                        systolic: e.systolic,
                    };
                }),
            };
        }),
    }));

    public lineChartOptions = computed<
        ChartConfiguration['options'] | undefined
    >(() => ({
        maintainAspectRatio: false,
        animation: false,
        scales: {
            x: {
                type: 'time',
                time: {
                    minUnit: 'hour',
                    displayFormats: {
                        second: 'HH:mm:ss', // Display time for minor ticks
                        minute: 'HH:mm', // Display time for minor ticks
                        hour: 'HH:mm', // Display time for minor ticks
                    },
                },
                min: this.minMaxDate().min,
                max: this.minMaxDate().max,
                position: 'top',
                display: true,
                grid: {
                    offset: false,
                    color: function (context) {
                        const dayWithMinutes = dayjs(context.tick.value).format(
                            'HH:mm'
                        );

                        if (dayWithMinutes === '00:00') {
                            return 'rgba(0, 0, 0, 0.6)';
                        }
                        if (dayWithMinutes === '08:00') {
                            return 'rgba(0, 0, 0, 0.4)';
                        }
                        if (dayWithMinutes === '16:00') {
                            return 'rgba(0, 0, 0, 0.4)';
                        }

                        return 'rgba(0, 0, 0, 0.1)';
                    },
                },
                ticks: {
                    autoSkip: false,
                    major: {
                        enabled: false,
                    },
                    callback: (tickValue) => {
                        const hourWithMinutes =
                            dayjs(tickValue).format('HH:mm');

                        if (
                            hourWithMinutes === '08:00' ||
                            hourWithMinutes === '16:00'
                        ) {
                            return hourWithMinutes;
                        }

                        if (
                            hourWithMinutes === '00:00' ||
                            hourWithMinutes === '04:00' ||
                            hourWithMinutes === '12:00' ||
                            hourWithMinutes === '20:00' ||
                            hourWithMinutes === '24:00'
                        ) {
                            return ' ';
                        }

                        return null;
                    },
                    font: (context) => {
                        const value = context.tick?.value;
                        const hourWithMinutes = dayjs(value).format('HH:mm');
                        if (
                            hourWithMinutes === '08:00' ||
                            hourWithMinutes === '16:00'
                        ) {
                            return {
                                size: 10,
                                weight: 'bold',
                            };
                        }

                        return {};
                    }
                },
            },
            y4: {
                type: 'linear',
                offset: true,
                position: 'left',
                stack: 'af',
                title: {
                    display: true,
                    text: '',
                },
                grid: {
                    display: true,
                    drawBorder: true, // Randlinie (Achse) zeichnen
                    offset: true,
                    color: '#ccc',
                },
                min: minMaxValues[VitalType.respiratoryRate].min,
                max: minMaxValues[VitalType.respiratoryRate].max,
                ticks: {
                    stepSize: 5,
                },
                border: {
                    // color: CHART_COLORS.AF,
                },
            },
            y3: {
                type: 'linear',
                offset: true,
                position: 'left',
                stack: 't',
                title: {
                    display: true,
                    text: '',
                },
                grid: {
                    display: true,
                    offset: false,
                    color: '#ccc',
                },
                min: minMaxValues[VitalType.temperatur].min,
                max: minMaxValues[VitalType.temperatur].max,
                ticks: {
                    stepSize: 1,
                },
                stackWeight: 1,
                border: {
                    // color: CHART_COLORS.T,
                },
            },
            y2: {
                type: 'linear',
                offset: true,
                position: 'left',
                stack: 'rr',
                title: {
                    display: true,
                    text: '',
                },
                grid: {
                    display: true,
                    offset: false,
                    color: '#ccc',
                },
                min: minMaxValues[VitalType.bloodPressure].min,
                max: minMaxValues[VitalType.bloodPressure].max,
                ticks: {
                    stepSize: 50,
                },
                stackWeight: 1,
                border: {
                    // color: CHART_COLORS.RR,
                },
            },
            y: {
                type: 'linear',
                offset: true,
                position: 'left',
                stack: 'hf',
                title: {
                    display: true,
                    text: '',
                },
                grid: {
                    display: true,
                    offset: false,
                    color: '#ccc',
                },
                min: minMaxValues[VitalType.heartRate].min,
                max: minMaxValues[VitalType.heartRate].max,
                ticks: {
                    stepSize: 20,
                },
                stackWeight: 1,
                border: {
                    // color: CHART_COLORS.HF,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                usePointStyle: true,
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }

                        console.log('context', context);

                        if (context.parsed.y !== null) {
                            if (
                                // @ts-ignore
                                context.raw?.systolic &&
                                // @ts-ignore
                                context.raw?.diastolic
                            ) {
                                // @ts-ignore
                                label += `${context.raw.systolic}/${context.raw.diastolic} mmHg`;
                            } else {
                                // @ts-ignore
                                label += context.raw?.originValue;
                            }
                        }
                        return label;
                    },
                },
            },
            customTitle: {
                x: {
                    display: true,
                    text: 'Month',
                    offsetX: 5,
                    offsetY: 5,
                    font: '12px Comic Sans MS',
                },
                y4: {
                    display: true,
                    color: CHART_COLORS.AF,
                    text: VitalType.respiratoryRate,
                },
                y3: {
                    display: true,
                    text: VitalType.temperatur,
                    color: CHART_COLORS.T,
                },
                y2: {
                    display: true,
                    text: VitalType.bloodPressure,
                    color: CHART_COLORS.RR,
                },
                y: {
                    display: true,
                    text: VitalType.heartRate,
                    color: CHART_COLORS.HF,
                },
            },
        },
    }));

    private minMaxDate = computed(() => {
        const dates = this.datesArray();

        const day1 = dayjs(dates[0]).startOf('day');
        const day2 = dayjs(dates[dates.length - 1]).endOf('day');

        return {
            min: day1.toISOString(),
            max: day2.toISOString(),
        };
    });

    public lineChartType: ChartType = 'line';

    public constructor(private readonly dialog: MatDialog) {}

    // events
    public chartClicked(event: any): void {
        const chartEvent = event as {
            event?: ChartEvent & { chart: Chart };
            active?: object[];
        };

        if (chartEvent.event?.type === 'click' && chartEvent.event?.native) {
            const chart = chartEvent.event.chart;

            const timeStamp =
                chart.scales['x'].getValueForPixel(chartEvent.event.x || 0) ||
                0;

            let temperatur = null;
            let heartRate = null;
            let respiratoryRate = null;
            let index = null;

            chartEvent.active?.forEach((element: any) => {
                const datasetIndex = element.datasetIndex;
                index = element.index;
                const dataSet = chart.data.datasets[datasetIndex];

                if (dataSet.label === VitalType.heartRate) {
                    // @ts-ignore
                    heartRate = dataSet.data[index]['y'];
                }
                if (dataSet.label === VitalType.respiratoryRate) {
                    // @ts-ignore
                    respiratoryRate = dataSet.data[index]['y'];
                }
                if (dataSet.label === VitalType.temperatur) {
                    // @ts-ignore
                    temperatur = dataSet.data[index]['y'];
                }
            });

            const dialogRef = this.dialog.open(VitalValuesComponent, {
                width: '500px',
                data: {
                    index,
                    timeStamp,
                    temperature: temperatur,
                    heartRate,
                    respiratoryRate,
                },
            });

            const diagSub = dialogRef
                .afterClosed()
                .subscribe((result: VitalDialogData) => {
                    if (result) {
                        this.datasets.update((datasets) => {
                            const ds = datasets.map((dataset) => {
                                const x = dayjs(result.timeStamp).toISOString();

                                if (
                                    dataset.label === VitalType.heartRate &&
                                    result.heartRate
                                ) {
                                    const dataPoint = {
                                        x,
                                        y: result.heartRate,
                                        originValue: result.heartRate,
                                    };
                                    if (result.index === null) {
                                        dataset.data.push(dataPoint);
                                    } else {
                                        dataset.data[result.index] = dataPoint;
                                    }
                                }
                                if (
                                    dataset.label === VitalType.bloodPressure &&
                                    result.bloodPressureDiastolic &&
                                    result.bloodPressureSystolic
                                ) {
                                    if (result.index === null) {
                                        dataset.data.push({
                                            x,
                                            y: result.bloodPressureSystolic,
                                            originValue:
                                                result.bloodPressureSystolic,
                                            diastolic:
                                                result.bloodPressureDiastolic,
                                            systolic:
                                                result.bloodPressureSystolic,
                                        });
                                    } else {
                                        dataset.data[result.index] = {
                                            x,
                                            originValue:
                                                result.bloodPressureSystolic,
                                            y: result.bloodPressureSystolic,
                                            diastolic:
                                                result.bloodPressureDiastolic,
                                            systolic:
                                                result.bloodPressureSystolic,
                                        };
                                    }
                                }
                                if (
                                    dataset.label === VitalType.temperatur &&
                                    result.temperatur
                                ) {
                                    if (result.index === null) {
                                        dataset.data.push({
                                            x,
                                            originValue: result.temperatur,
                                            y: result.temperatur,
                                        });
                                    } else {
                                        dataset.data[result.index] = {
                                            x,
                                            originValue: result.temperatur,
                                            y: result.temperatur,
                                        };
                                    }
                                }
                                if (
                                    dataset.label ===
                                        VitalType.respiratoryRate &&
                                    result.respiratoryRate
                                ) {
                                    if (result.index === null) {
                                        dataset.data.push({
                                            x,
                                            originValue: result.respiratoryRate,
                                            y: result.respiratoryRate,
                                        });
                                    } else {
                                        dataset.data[result.index] = {
                                            x,
                                            originValue: result.respiratoryRate,
                                            y: result.respiratoryRate,
                                        };
                                    }
                                }
                                return dataset;
                            });

                            // Sort the dataset by measure timepoint, so they are correctly shown in the chart
                            ds.forEach((e) =>
                                e.data.sort((d1, d2) =>
                                    d1.x.localeCompare(d2.x)
                                )
                            );

                            return ds;
                        });
                    }

                    diagSub.unsubscribe();
                });
        }
    }

    public downloadChartAsImage(): void {
        if (this.chart) {
            const base64Image = this.chart.toBase64Image();
            if (base64Image) {
                const link = document.createElement('a');
                link.href = base64Image;
                link.download = 'chart.png';
                link.click();
            }
        }
    }
}
