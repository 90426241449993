import { Cp2ApiService } from '../cp2-api.service';
import { Injectable } from '@angular/core';
import { DataRepositoryService } from '../datarepository.service';
import {
    Patient_Details,
    PatientListGroup,
    PatientListIdentifier,
    VC_Task,
    VC_Visit_Record,
    VcPatientListItem,
    ViewContent,
} from '../../models/view-content.models/view-content.model';
import { AccessFacadeService } from '../facades/access-facade.service';
import { BehaviorSubject, combineLatest, fromEvent, startWith, Subject, Subscription } from 'rxjs';
import { CP2_User } from '../../models/view-content.models/view-content-personal-domain.model';
import * as jose from 'jose';
import { UserToken } from '../../models/auth.model';
import dayjs from 'dayjs';
import { DeviceFacadeService } from '../facades/device-facade.service';
import { NgEventBus } from 'ng-event-bus';
import { SettingsFacadeService } from '../facades/settings-facade.service';
import { Case, Patient } from '../../models/view-content.models/view-content-patient-domain.model';
import { Place } from '../../models/view-content.models/view-content-organization-domain.model';
import { Diagnose, Task, Visit_Record } from '../../models/view-content.models/view-content-clinic-domain.model';
import { Mutex } from 'async-mutex';

/**
 * Service that manages caching and synchronization of ViewContent data.
 *
 * This service handles fetching ViewContent data from the local repository (local SQLite DB)
 * and synchronizing with the remote API. It listens to server-sent events (SSE) via Mercure
 * to receive updates and refresh the local cache accordingly. It also manages pending PUT
 * requests when offline, ensuring data consistency when the connection is restored.
 */
@Injectable({
    providedIn: 'root',
})
export class ViewContentCacheService {
    /** Sets the verbosity level of the service; `true` for high verbosity */
    public static debugMode = false;
    /** Will forward the locators pushed by Mercure over SSE. It is intended for the component to know when new data is available */
    public incomingLocator$ = new Subject<string>();
    /** Will forward ViewContents that are fetched from the API after a locator comes over Mercure */
    public incomingViewContents$ = new Subject<ViewContent<unknown>>();
    public isSyncingDownloads$ = new BehaviorSubject<boolean>(false);
    public isSyncingUploads$ = new BehaviorSubject<boolean>(false);
    private isOnline = navigator.onLine;
    private userToken: UserToken | undefined;
    private user: CP2_User | undefined;
    private locatorSubscription: Subscription | undefined;
    private syncSubscription: Subscription | undefined;

    private vcDownloadTasks: (() => Promise<boolean>)[] = [];
    private vcDownloadTasksMutex = new Mutex();
    private caseListDownloadTasks: (() => Promise<boolean>)[] = [];
    private caseListDownloadTasksMutex = new Mutex();

    /** @deprecated: only used in this.syncDownloadsOld, which is also deprecated */
    private isSyncing = false;

    public constructor(
        private accessFacade: AccessFacadeService,
        private api: Cp2ApiService,
        private deviceFacade: DeviceFacadeService,
        private eventBus: NgEventBus,
        private repo: DataRepositoryService,
        private settingsFacade: SettingsFacadeService
    ) {
        this.initOnlineStatus();

        this.accessFacade.userTokens$.subscribe((t) => {
            this.log('new userToken value', { t });
            if (!t?.token) {
                this.userToken = undefined;
                this.user = undefined;
                return;
            }
            this.userToken = t.token;

            const decodedToken = jose.decodeJwt(this.userToken.access_token);
            this.user = {
                id: this.userToken.related_user_id,
                uuid: decodedToken['sub'] ?? '',
                surname: decodedToken['family_name'] as string,
                name: decodedToken['given_name'] as string,
            };

            this.syncPendingPuts().then();
        });

        this.deviceFacade.device$.subscribe((d) => {
            if (d.device.deviceId)
                this.initIncomingLocatorsListener(d.device.deviceId).then((e) => {
                    if (ViewContentCacheService.debugMode) console.log('VC Cache: incoming locator listener started');
                });
        });
    }

    public async getAllLocatorsForCaseId(caseId: string): Promise<string[]> {
        if (this.userToken?.access_token && this.isOnline)
            return await this.api.getAllLocatorsForCaseId(caseId, this.userToken.access_token);

        const repoLocators = await this.repo.getAllLocatorsForCaseId(caseId);
        if (repoLocators.length) return repoLocators;

        return [];
    }

    public async getCaseList(): Promise<PatientListGroup[]> {
        // Online fetch and sync is disabled, as it should happen in background thanks to Mercure
        // if (this.userToken && this.isOnline) {
        //     return await this.fetchAndSyncCaseListFromApi();
        // } else {
        //     return await this.buildCaseListFromRepo();
        // }

        return await this.buildCaseListFromRepo();
    }

    public async getViewContentForLocator<T>(locator: string): Promise<ViewContent<T> | undefined | null> {
        const repoRes = await this.repo.getFullViewContentForLocator<T>(locator);
        this.log(`ViewContent read from DB for locator:${locator}`, repoRes);

        if (!repoRes) {
            if (!this.userToken?.access_token) {
                console.warn('No token available to fetch ViewContent');
                return null;
            }
            const apiRes = await this.api.getVcForLocator<T>(locator, this.userToken.access_token, undefined, {
                send_history: 'false',
            });
            this.log(`ViewContent read from API for locator:${locator}`, repoRes);
            if (!apiRes) return null;

            if (this.user) {
                // const updateRes = await this.repo.createOrUpdateViewContent(apiRes, this.user);
                const updateRes = await this.repo.syncViewContent(apiRes, this.user);
                this.log('getViewContentForLocator: vc updated in repo', {
                    apiRes,
                    updateRes,
                });

                return updateRes;
            }

            return apiRes;
        }

        return repoRes;
    }

    public async getViewContentBatchForLocator<T>(locator: string): Promise<ViewContent<T>[] | undefined | null> {
        const repoRes = await this.repo.getBatchFullViewContentsForLocator<T>(locator);

        this.log(`ViewContents read from DB for locator:${locator}`, repoRes);

        if (!repoRes?.length) {
            if (!this.userToken?.access_token) {
                console.warn('No token available to fetch ViewContent');
                return null;
            }

            const apiRes = await this.api.getVcBatchForLocator<T>(locator, this.userToken.access_token);
            this.log(`ViewContent read from API for locator:${locator}`, repoRes);
            if (!apiRes?.length) return [];

            if (this.user) {
                for (const vc of apiRes) {
                    const updateRes = await this.repo.createOrUpdateViewContent(vc, this.user);
                    this.log('getViewContentForLocator: vc updated in repo', {
                        apiRes,
                        updateRes,
                    });
                }
            }

            return apiRes;
        }

        return repoRes;
    }

    public async saveViewContent<T>(vc: ViewContent<T>): Promise<ViewContent<T> | undefined> {
        if (this.isOnline) {
            try {
                // Write the viewContent in the API and update the DB with what the API delivers back
                if (!this.userToken?.access_token) {
                    console.warn('No token available to fetch ViewContent');
                    return;
                }
                const apiResult = await this.api.putViewContent<T>(vc, this.userToken.access_token);
                this.log('saveViewContent: online PUT', { apiResult });
            } catch (e) {
                // If there is something wrong, at least update DB
                console.warn('Error putting ViewContent to API', e);
                if (this.user) {
                    const cuRes = await this.repo.createOrUpdateViewContent(vc, this.user);
                    const pendingPutRes = await this.createPendingPutEntry(vc);

                    this.log('saveViewContent: online error', {
                        cuRes,
                        pendingPutRes,
                    });
                }

                return vc;
            }
        } else {
            // If offline, just update DB
            if (this.user) {
                const dbr1 = await this.repo.createOrUpdateViewContent(vc, this.user);
                const dbr2 = await this.createPendingPutEntry(vc);
                this.incomingViewContents$.next(vc);

                this.log('saveViewContent: offline', { dbr1, dbr2 });
            }
        }

        return;
    }

    public async fetchAndSyncCaseListFromApi(): Promise<PatientListGroup[]> {
        if (!this.userToken?.access_token) {
            console.warn('No token available to fetch ViewContent');
            return [];
        }

        const apiRes = await this.api.getCaseListForUser(this.userToken.related_user_id, this.userToken.access_token);

        for (const item of apiRes) {
            await this.repo.saveCaseListIdentifier(item.listIdentifier);
            const localListIdentifier = await this.repo.getCaseListEntriesForNameType(
                item.listIdentifier.name,
                item.listIdentifier.list_type
            );

            for (const p of item.patients) {
                const vcCase: ViewContent<Case> = p.patient_details.case;
                const vcPatient: ViewContent<Patient> = p.patient_details.patient;
                const vcPlace: ViewContent<Place> = p.patient_details.current_place;
                const vcDiagnose: ViewContent<Diagnose> | undefined = p.patient_details.last_diagnosis;
                const vcTasks: VC_Task[] = p.tasks;
                const vcVisitRecord: VC_Visit_Record | undefined = p.visit_record;

                if (ViewContentCacheService.debugMode) {
                    this.log('Patient', { p });
                }

                if (this.user) {
                    await this.repo.syncViewContent(vcCase, this.user);
                    await this.repo.syncViewContent(vcPatient, this.user);
                    await this.repo.syncViewContent(vcPlace, this.user);
                    if (vcVisitRecord?.locator) await this.repo.syncViewContent(vcVisitRecord, this.user);
                    if (vcDiagnose?.locator) await this.repo.syncViewContent(vcDiagnose, this.user);
                    for (const vcTask of vcTasks) await this.repo.syncViewContent(vcTask, this.user);
                    if (localListIdentifier)
                        await this.repo.includeCaseInList(vcCase.related_case_id, localListIdentifier);
                }
            }
        }

        return apiRes;
    }

    private async createPendingPutEntry<T>(vc: ViewContent<T>): Promise<void> {
        // Find out the exact timestamp for the pending PUT
        const last = await this.repo.getFullViewContentForLocator(vc.locator);
        this.log('createPendingPutEntry: Fetched full view content from repo', {
            last,
        });

        const lastHistory = last?.history?.sort((e1, e2) => e2.modifiedAt.localeCompare(e1.modifiedAt))[0];
        const timestamp = lastHistory?.modifiedAt ?? dayjs().toISOString();

        this.log('createPendingPutEntry: Computed timestamp for pending PUT', {
            timestamp,
            lastHistory,
        });

        // Create the pending PUT entry
        if (this.user) {
            const pendingPutRes = await this.repo.createPendingPutVc({
                id: -1,
                vcLocator: vc.locator,
                user: this.user,
                timestamp,
            });
            this.log('createPendingPutEntry: Created pending PUT entry in repo', {
                pendingPutRes,
            });
        }
    }

    private async initIncomingLocatorsListener(deviceId: string): Promise<void> {
        if (this.locatorSubscription) this.locatorSubscription.unsubscribe();
        if (this.syncSubscription) this.syncSubscription.unsubscribe();

        const userId = this.user?.id;

        this.locatorSubscription = this.eventBus.on(`sse:device/${deviceId}`).subscribe((e) => {
            const locator = e.data as string;
            // if (locator.includes('case.overview.visit_record.1986')) console.log('incoming locator', e);
            console.log('incoming locator', locator);
            this.addTaskToPendingDownloads(locator);
            this.incomingLocator$.next(locator);
            this.syncDownloads();
        });
    }

    private initOnlineStatus(): void {
        // Listen to the online and offline events from the window object
        const online$ = fromEvent(window, 'online').pipe(startWith(navigator.onLine));
        const offline$ = fromEvent(window, 'offline').pipe(startWith(!navigator.onLine));
        const settings$ = this.settingsFacade.settings$;

        // Combine observables and update this.isOnline considering the simulateOffline setting
        combineLatest([online$, offline$, settings$]).subscribe(([online, offline, settings]) => {
            // Check the simulateOffline setting; if true, force offline
            this.isOnline = navigator.onLine && !settings.simulateOffline;

            // Perform sync if we are actually online
            if (this.isOnline) {
                this.syncPendingPuts().then();
            }
        });
    }

    private async singleDownloadTask(locator: string): Promise<boolean> {
        this.isSyncingDownloads$.next(true);
        try {
            if (!this.userToken?.access_token) return false;
            if (!this.user) return false;

            const apiVc = await this.api.getVcForLocator(locator, this.userToken.access_token, undefined, {
                send_history: 'false',
            });

            if (!apiVc) return false;

            let syncedVc: ViewContent<unknown> | null = null;
            syncedVc = await this.repo.syncViewContent(apiVc, this.user);
            this.incomingViewContents$.next(syncedVc ?? apiVc);

            return true;
        } catch (e) {
            console.error(e);
            return false;
        } finally {
            this.isSyncingDownloads$.next(false);
        }
    }

    /** @deprecated */
    private async singleDownloadTaskOld(locator: string): Promise<boolean> {
        if (!this.userToken?.access_token) return false;
        if (!this.user) return false;

        const apiVc = await this.api.getVcForLocator(locator, this.userToken.access_token, undefined, {
            send_history: 'false',
        });

        if (!apiVc) return false;
        let syncedVc: ViewContent<unknown> | null = null;
        try {
            syncedVc = await this.repo.syncViewContent(apiVc, this.user);
        } catch (e) {
            console.error(e);
        }
        this.incomingViewContents$.next(syncedVc ?? apiVc);

        return true;
    }

    private async caseListDownloadTask(locator: string): Promise<boolean> {
        if (!this.userToken || !this.isOnline) return false;

        try {
            const apiRes = await this.api.getCaseListForUser(
                this.userToken.related_user_id,
                this.userToken.access_token
            );

            for (const item of apiRes) {
                const saveListRes = await this.repo.saveCaseListIdentifier(item.listIdentifier);
                if (saveListRes.changes?.lastId === undefined) throw new Error('Error saving case list identifier');

                for (const l of item.patients) {
                    // await this.repo.createOrUpdateViewContent(l.patient_details.case, this.user);
                    const list: PatientListIdentifier = {
                        ...item.listIdentifier,
                        id: saveListRes.changes.lastId,
                    };
                    await this.repo.includeCaseInList(l.patient_details.case.related_case_id, list);
                }
            }
        } catch (e) {
            console.error(`Error downloading case list for locator ${locator}`, e);
            return false;
        }

        return true;
    }

    private addTaskToPendingDownloads(locator: string): void {
        let task: () => Promise<boolean>;
        const userId = this.user?.id;
        if (locator.startsWith('case.list.item.')) return;
        if (locator === `case.list.${userId}`) {
            task = async () => await this.caseListDownloadTask(locator);
            this.caseListDownloadTasksMutex.runExclusive(() => this.caseListDownloadTasks.push(task));
        } else {
            task = async () => await this.singleDownloadTask(locator);
            this.vcDownloadTasksMutex.runExclusive(() => this.vcDownloadTasks.push(task));
        }
    }

    /** @deprecated */
    private async syncDownloadsOld(): Promise<void> {
        if (this.isSyncing) return;
        this.isSyncing = true;

        const failedSingleTasks: (() => Promise<boolean>)[] = [];
        const failedListTasks: (() => Promise<boolean>)[] = [];
        try {
            // It is necessary to download case.list.{user_id} last to avoid foreign key constraint violations
            for (const task of this.vcDownloadTasks) {
                failedSingleTasks.push(task);
                const res = await task();
                if (res) failedSingleTasks.pop();
            }

            for (const task of this.caseListDownloadTasks) {
                failedListTasks.push(task);
                const res = await task();
                if (res) failedListTasks.pop();
            }
        } catch (e) {
            console.error('Error syncing', e);
        } finally {
            this.vcDownloadTasks = failedSingleTasks;
            this.caseListDownloadTasks = failedListTasks;
            this.isSyncing = false;
        }
    }

    private async syncDownloads(): Promise<void> {
        this.log(
            `syncDownloads: Starting sync of ${this.vcDownloadTasks.length} single tasks and ${this.caseListDownloadTasks.length} list tasks`
        );

        this.isSyncingDownloads$.next(true);
        try {
            // It is necessary to download case.list.{user_id} last to avoid foreign key constraint violations
            await this.vcDownloadTasksMutex.runExclusive(async () => {
                for (const task of this.vcDownloadTasks) {
                    const res = await task();
                    if (res) this.vcDownloadTasks = this.vcDownloadTasks.filter((t) => t !== task);
                }
            });

            await this.caseListDownloadTasksMutex.runExclusive(async () => {
                for (const task of this.caseListDownloadTasks) {
                    const res = await task();
                    if (res) this.caseListDownloadTasks = this.caseListDownloadTasks.filter((t) => t !== task);
                }
            });
        } finally {
            this.isSyncingDownloads$.next(false);
        }

        this.log(
            `syncDownloads: Finished sync. Missing ${this.vcDownloadTasks.length} single tasks and ${this.caseListDownloadTasks.length} list tasks`
        );
    }

    private async syncPendingPuts(): Promise<void> {
        this.isSyncingUploads$.next(true);
        try {
            // Get all pending operations and log the result
            const pendingPuts = await this.repo.getAllPendingPutVc();
            this.log('syncPendingPuts: Fetched all pending PUT operations', {
                pendingPuts,
            });

            for (const e of pendingPuts) {
                // Get the original viewContent and the corresponding history element
                const viewContent = await this.repo.getFullViewContentForLocator(e.vcLocator);
                this.log('syncPendingPuts: Fetched viewContent for locator from repo', {
                    vcLocator: e.vcLocator,
                    viewContent,
                });

                const histElement = viewContent?.history?.find((h) => h.modifiedAt === e.timestamp);
                this.log('syncPendingPuts: Found matching history element', {
                    histElement,
                });

                if (viewContent && histElement) {
                    viewContent.data = histElement.data;
                    viewContent.updated_at = histElement.modifiedAt;
                    viewContent.history = undefined;
                    viewContent.form = undefined;
                    viewContent.i18n = undefined;

                    this.log('syncPendingPuts: Prepared viewContent for PUT request', {
                        viewContent,
                        existsUserToken: !!this.userToken,
                    });

                    if (this.userToken) {
                        try {
                            // Send the PUT request to the API and log the response
                            const apiRes = await this.api.putViewContent(viewContent, this.userToken?.access_token);
                            this.log('syncPendingPuts: Sent PUT request to API', {
                                apiRes,
                            });

                            // Delete the pending PUT from the database and log the response
                            const dbDeleteRes = await this.repo.deletePendingPutVc(e.id);
                            this.log('syncPendingPuts: Deleted pending PUT entry from repo', { dbDeleteRes });
                        } catch (error) {
                            // Log any errors that occur during the PUT request or delete operation
                            this.log('syncPendingPuts: Error during PUT or DB delete operation', {
                                error,
                                viewContent,
                            });
                        }
                    }
                }
            }

            this.log('syncPendingPuts: Completed all pending PUT operations');
        } finally {
            this.isSyncingUploads$.next(false);
        }
    }

    private async buildCaseListFromRepo(): Promise<PatientListGroup[]> {
        const listIdentifiers = await this.repo.getAllCaseListEntries();
        const repoRes: PatientListGroup[] = listIdentifiers.map((li) => ({
            listIdentifier: li,
            patients: [],
        }));

        const allCaseId = await this.repo.getAllCaseId();
        for (const caseId of allCaseId) {
            const vcCase = await this.repo.getFullViewContentForLocator<Case>(`case.details.${caseId}`);
            const patientId = vcCase?.related_patient_id;
            const vcPatient = await this.repo.getFullViewContentForLocator<Patient>(`patient.details.${patientId}`);
            const vcPlace = await this.repo.getFullViewContentForLocator<Place>(`case.overview.place.${caseId}`);
            const vcDiagnose = await this.repo.getFullViewContentForLocator<Diagnose>(
                `case.overview.diagnosis.${caseId}`
            );
            const vcVisitRecord = await this.repo.getFullViewContentForLocator<Visit_Record>(
                `case.overview.visit_record.${caseId}`
            );
            const allLocators = await this.repo.getAllLocatorsForCaseId(caseId);
            const taskLocators = allLocators.filter((l) => l.startsWith('case.communication.tasks'));
            const vcTasks: VC_Task[] = (
                await Promise.all(taskLocators.map(async (l) => await this.repo.getFullViewContentForLocator<Task>(l)))
            ).filter((e): e is VC_Task => e !== null);

            if (vcCase && vcPatient && vcPlace && vcDiagnose) {
                const patientDetails: Patient_Details = {
                    case: vcCase,
                    patient: vcPatient,
                    current_place: vcPlace,
                    last_diagnosis: vcDiagnose,
                };
                const patientListItem: VcPatientListItem = {
                    patient_details: patientDetails,
                    visit_record: vcVisitRecord ?? undefined,
                    tasks: vcTasks,
                };

                const listIds = await this.repo.getListIdForCase(vcCase.related_case_id);
                for (const listId of listIds) {
                    const list = repoRes.find((l) => l.listIdentifier.id === listId);
                    list?.patients.push(patientListItem);
                }
            }
        }

        return repoRes;
    }

    private log(...l: any): void {
        if (ViewContentCacheService.debugMode) {
            console.log(...l);
        }
    }
}
